import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { message } from 'antd';
import App from './App';
import Login from './Login';
import './index.css';
import reportWebVitals from './reportWebVitals';

const Root = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onFinish = (values) => {
    if (values.username === 'jkent100' && values.password === 'K8Ru4ub2') {
      setIsLoggedIn(true);
      message.success('Login successful!');
    } else {
      console.log('Failed:', values);
      message.error('Incorrect username or password!');
    }
  };

    return (
      <React.StrictMode>
        {isLoggedIn ? <App logout={() => setIsLoggedIn(false)} /> : <Login onFinish={onFinish} />}
      </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();