import { useState, useRef, useEffect } from 'react';
import { Form, Input, Select, Upload, Button, Tooltip, Drawer, List, Spin, Card, message, Modal } from 'antd';
import { UploadOutlined, LogoutOutlined, MenuOutlined, CheckCircleOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { GoogleLogin } from '@react-oauth/google';
import './App.css';
import axios from 'axios';

const { Option } = Select;

function App(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [queue, setQueue] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const formRef = useRef(null);

  const handleMenuClick = () => {
    setDrawerVisible(!drawerVisible);
  };

  const onFinish = async (values) => {
    message.loading('Form submitted, processing started.');
    setIsProcessing(true);
    setQueue([...queue, { ...values, status: 'pending' }]);
    setDrawerVisible(true);
  };

  useEffect(() => {
    if (queue.length > 0 && queue[queue.length - 1].status === 'pending') {
      processQueue();
    }
  }, [queue]);

  const handleDismiss = (index) => {
      setQueue(queue.filter((item, i) => i !== index));
  };

  const handleFormClick = () => {
    if (isProcessing) {
      setIsModalVisible(true);
    }
  };

  const processQueue = async () => {
    console.log('Processing Queue');
    console.log(queue);
    if (queue.length > 0) {
      const pendingIndex = queue.findIndex(item => item.status === 'pending');
      if (pendingIndex !== -1) {
        const values = queue[pendingIndex];
        const { transcriptAction, model, audioFiles, emails } = values;

        const formData = new FormData();

        if (audioFiles && audioFiles.length > 0) {
          audioFiles.forEach(file => {
            formData.append('audio', file.originFileObj);
          });
          formData.append('model_name', model);
          formData.append('prompt', transcriptAction);
          if (emails && emails.length > 0) {
            formData.append('emails', JSON.stringify(emails));
          }

          try {
            const response = await axios.post('/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log(response.data);
            message.success('Processing completed.');
            setIsProcessing(false);
            setQueue(queue.map((item, index) => index === pendingIndex ? { ...item, status: 'done' } : item));
          } catch (error) {
            console.error(error);
            message.error('Processing failed.');
            setIsProcessing(false);
            setQueue(queue.map((item, index) => index === pendingIndex ? { ...item, status: 'error' } : item));
          }
        } else {
          console.error('No file was selected.');
        }
      }
    }
  }

  return (
    <div className="App centered-form">

      {/*<GoogleLogin*/}
      {/*    onSuccess={credentialResponse => {*/}
      {/*      console.log(credentialResponse);*/}
      {/*    }}*/}
      {/*    onError={() => {*/}
      {/*      console.log('Login Failed');*/}
      {/*    }}*/}
      {/*    scopes={[*/}
      {/*        "https://www.googleapis.com/auth/gmail.compose",*/}
      {/*        "https://www.googleapis.com/auth/gmail.send",*/}
      {/*        "https://www.googleapis.com/auth/gmail.readonly"*/}
      {/*    ]}*/}
      {/*/>*/}

      <Tooltip title="Queue">
        <Button
            shape="circle"
            icon={<MenuOutlined />}
            style={{ position: 'absolute', left: 20, top: 20 }}
            onClick={handleMenuClick}
        />
      </Tooltip>
          <Drawer
          title="Processing Queue"
          placement="left"
          closable={false}
          onClose={handleMenuClick}
          open={drawerVisible}
        >
          <List
            dataSource={queue}
            renderItem={(item, index) => (
            <List.Item>
              <Card style={{ width: '100%' }}>
              {item.status === 'done' && <CloseOutlined
                    onClick={() => handleDismiss(index)}
                    style={{ cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }}
                  />
              }
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <div>
                    <h4>{item.audioFiles[0].name}</h4>
                    <div>Prompt: {item.transcriptAction}</div>
                    <div>Model: {item.model}</div>
                  </div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    {item.status === 'pending' && <Spin />}
                    {item.status === 'done' && (
                      <>
                        <CheckCircleOutlined />
                      </>
                    )}
                    {item.status === 'error' && (
                      <>
                        <WarningOutlined />
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
          />
        </Drawer>
      <Tooltip title="Logout">
        <Button
          shape="circle"
          icon={<LogoutOutlined />}
          style={{ position: 'absolute', right: 20, top: 20 }}
          onClick={props.logout}
        />
      </Tooltip>
      <Modal title="Processing" open={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)}>
        <p>Your request is currently processing, please wait for it to finish</p>
      </Modal>
      <Form ref={formRef} onFinish={onFinish} className="form-with-shadow" disabled={isProcessing} style={{ maxWidth: 600, marginBottom: 0 }} onClick={handleFormClick}>
        <Form.Item
          name="audioFiles"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.fileList;
          }}
          rules={[
            { required: true, message: 'Please upload a file!' },
            {
              validator: (_, value) => {
                if (!value || !value[0]) {
                  return Promise.resolve();
                }
                const file = value[0].originFileObj;
                if (!file.type.startsWith('audio/')) {
                  return Promise.reject(new Error('You can only upload audio files!'));
                }
                return Promise.resolve();
              },
            },
          ]}
      >
        <Upload.Dragger name="files" beforeUpload={() => false}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>
      </Form.Item>

        <Form.Item
            name="transcriptAction"
            label={"Prompt"}
            rules={[{ required: true, message: 'Please input your action!' }]}
        >
          <Input.TextArea placeholder="What do you want to do with the transcript?" />
        </Form.Item>

        <Form.Item
            name="model"
            rules={[{
              required: true,
              message: 'Please select a model!'
            }]}
            label={"Model"}>
          <Select placeholder="Select a model">
            <Option value="whisper-medium">Whisper Medium</Option>
            <Option value="whisper-large">Whisper Large</Option>
            <Option value="base">Base</Option>
            <Option value="enhanced">Enhanced</Option>
            <Option value="nova">Nova</Option>
            <Option value="nova-2">Nova 2</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="emails"
          label={"Emails"}
          rules={[
            {
              required: true,
              message: 'Please input your E-mail!'
            },
            {
              validator: (_, value) =>
                Array.isArray(value) && value.length > 0
                  ? Promise.resolve()
                  : Promise.reject(new Error('At least one email is required!'))
            }
          ]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Enter Emails"
            tokenSeparators={[',']}
          />
        </Form.Item>

        <Form.Item className="submit-button" style={{ marginTop:"16px", marginBottom:0}}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default App;