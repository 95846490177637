import React from "react";
import { Button, Form, Input } from "antd";
import "./Login.css";

const Login = ({ onFinish }) => (
  <div className="Login centered-form">
    <Form
      name="login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      className="form-with-shadow"
    >
      <h2 className="form-header">Welcome to Council Tap</h2>
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input className="input-field" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password className="input-field" />
      </Form.Item>

      <Form.Item className="submit-button-item">
        <Button type="primary" htmlType="submit" className="submit-button">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </div>
);

export default Login;
